import Axios from "axios";
import ApiUrl from "../../env/enviroment";

const baseEndpoint = "/api/RedireccionJoyeria/Clicks";
const baseEndpointJoyeria = "/api/RedireccionJoyeria/ObtenerTabla";
const Clicks = async () => {
   return await Axios.get(ApiUrl + baseEndpoint); 
}

const DatosJoyeria = async () => {
    return await Axios.get(ApiUrl + baseEndpointJoyeria); 
}

const ApiRedireccionJoyeria = {
    Clicks,
    DatosJoyeria
}
export default ApiRedireccionJoyeria;