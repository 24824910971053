import React, { useEffect, useState } from "react";
import ApiDashboardArirang from "../../../../../js/api/env/global/DashboardArirang";
import { openNotification } from "../../../../controls/Notifications";
import { Pie, Bar } from "@ant-design/charts";
import { Typography, Tabs } from "antd";
import { BarChartOutlined, PieChartOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { TabPane } = Tabs;

const DashboardHistorico = () => {
  const [dataHistorica, setDataHistorica] = useState([]);
  useEffect(() => {
    Promise.all([ApiDashboardArirang.DatoHistorico()])
      .then((res) => {
        const dashboardHistorico = res[0];

        if (dashboardHistorico.data.success) {
          const conversion = dashboardHistorico.data.dataList.map(
            (x) => (
              {
                "datosVendedor": `${x.nombreCompleto}, ${x.nombreCargo} ${x.nombreSucursal}`,
                "interacciones": x.clicksVendedor
              }
            )
          )
          setDataHistorica(conversion);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema "+err
        );
      });
  }, []);

  const configPie = {
    appendPadding: 10,
    data: dataHistorica,
    angleField: 'interacciones',
    colorField: 'datosVendedor',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage} ({value})',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ]
  };

  const configBar = {
    appendPadding: 10,
    data: dataHistorica,
    xField: "interacciones",
    yField: "datosVendedor",
    seriesField: "datosVendedor",
    legend: {
      position: "top-left",
    },
  };
  return (
    <div>
      <div>
        <Title level={4}>
          De un total de{" "}
          {dataHistorica.reduce((sum, value) => sum + value.interacciones, 0)}{" "}
          interacciones.
        </Title>
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <BarChartOutlined />
              Barras
            </span>
          }
          key="1"
        >
          <div className="chartContainer">
            <Bar height={1100} {...configBar} />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <PieChartOutlined />
              Torta
            </span>
          }
          key="2"
        >
          <div className="chartContainer">
            <Pie height={1100} {...configPie} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
export default DashboardHistorico;
