import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Affix, Layout, Menu } from 'antd';
import { handlerLogout } from '../../../js/api/env/global/auth/logout'
import {
  FileSearchOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  HomeOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  DollarOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import helperSession from './../../../js/api/helpers/helpersSession'

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class Navbar extends Component {
    state = {
        collapsed: false,
      };
    
      onCollapse = collapsed => {
        this.setState({ collapsed });
      };
      render(){
        const { collapsed } = this.state;
        if(helperSession.GetAccount("nombreEmpresa").includes("Arirang")){
          return (
            <>
              <Affix>
                <Sider
                  collapsible
                  collapsed={collapsed}
                  onCollapse={this.onCollapse}
                  style={{ backgroundColor: "#5631DE", height: "100vh" }}
                >
                  <div className="logo">
                    <h1>
                      <span className="logo-titulo">Analytics Arirang</span>
                    </h1>
                  </div>
                  <Menu
                    theme="dark"
                    defaultSelectedKeys={["sub1"]}
                    mode="inline"
                    style={{ backgroundColor: "#5631DE" }}
                  >
                    <Menu.Item key="1" icon={<HomeOutlined/>}>
                        <Link to="/Home">Inicio</Link>
                      </Menu.Item>
                    <SubMenu
                      key="sub1"
                      icon={<FileSearchOutlined />}
                      title="Reporte Individual"
                    >
                      <Menu.Item key="2">
                        <Link to="/ReportesArirang">Detalle de Interacciones</Link>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<PieChartOutlined />}
                      title="Dashboard"
                    >
                      <Menu.Item key="3" icon={<WhatsAppOutlined />}>
                        <Link to="/DashboardArirang">WhatsApp</Link>                        
                      </Menu.Item>
                      <Menu.Item key="4" icon={<InstagramOutlined />}>
                        <Link to="/DashboardArirangIG">Instagram</Link>                        
                      </Menu.Item>                     
                    </SubMenu>
                    <SubMenu
                      key="sub3"
                      icon={<DollarOutlined />}
                      title="Ventas"
                    >
                      <Menu.Item key="5" icon={<ProfileOutlined />}>
                        <Link to="/Ventas">My Home</Link>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      key="6"
                      icon={<PoweroffOutlined />}
                      onClick={handlerLogout}
                    >
                      Cerrar Sesion
                    </Menu.Item>
                  </Menu>
                </Sider>
              </Affix>           
            </>
          );
        }
        if(helperSession.GetAccount("nombreEmpresa").includes("Joyeria Fernandez")){
          return (
            <>
              <Affix>
                <Sider
                  collapsible
                  collapsed={collapsed}
                  onCollapse={this.onCollapse}
                  style={{ backgroundColor: "#5631DE", height: "100vh" }}
                >
                  <div className="logo">
                    <h1>
                      <span className="logo-titulo">Analytics Joyeria Fernandez</span>
                    </h1>
                  </div>
                  <Menu
                    theme="dark"
                    defaultSelectedKeys={["sub1"]}
                    mode="inline"
                    style={{ backgroundColor: "#5631DE" }}
                  >
                    <Menu.Item key="1" icon={<HomeOutlined/>}>
                        <Link to="/Home">Inicio</Link>
                      </Menu.Item>
                    <SubMenu
                      key="sub1"
                      icon={<FileSearchOutlined />}
                      title="Reporte Individual"
                    >
                      <Menu.Item key="3">
                        <Link to="/ReportesJoyeria">Detalle de Interacciones</Link>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<PieChartOutlined />}
                      title="Dashboard"
                    >
                      <Menu.Item key="5" icon={<InstagramOutlined />}>
                        <Link to="/DashboardJoyeria">Instagram</Link>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      key="6"
                      icon={<PoweroffOutlined />}
                      onClick={handlerLogout} 
                    >
                      Cerrar Sesion
                    </Menu.Item>
                  </Menu>
                </Sider>
              </Affix>           
            </>
          );
        }

      }
  
}
