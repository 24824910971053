// const dateFormat = [
//   "YYYY-MM-DD",
//   "DD-MM-YYYY",
//   "MM-DD-YYYY",
//   "YYYY-MM-DD HH:mm:ss",
//   "DD-MM-YYYY HH:mm:ss",
//   "MM-DD-YYYY HH:mm:ss",
// ];

const dateFormat = [
  "MM-DD-YYYY",
  "MM-DD-YYYY HH:mm:ss",
];

export default dateFormat;
