import React from "react";
import { Layout } from "antd";
import HeaderBar from './HeaderBar'
import Navbar from './Navbar';
import './LayoutLogin.css'

const { Content, Footer } = Layout;
export default function LayoutLogin(props) {

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Navbar/>      
          <Layout className="site-layout">
              <HeaderBar/>
              <Content style={{ margin: '10px 16px' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                  {props.children} 
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>Power Data S.A. ©2021</Footer>
          </Layout>
      </Layout>
    </div>
  );
}
