import React from "react";
import { Result, Button } from "antd";

export default function NotFound() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="No sabes a donde ir? Nosotros tampoco, pero te podemos llevar de vuelta a casa."
        extra={
          <Button type="primary" href="/Login">
            Volver a Inicio
          </Button>
        }
      />
    </div>
  );
}
