import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/charts";
import { openNotification } from "../../../controls/Notifications";
import ApiRedireccionArirang from "../../../../js/api/env/global/RedireccionArirang";
import dateFormat from "../../../../js/api/helpers/dateFormat";
import moment from "moment";
import "moment/locale/es";
import { Typography } from "antd";

moment.locale("es");

const { Title } = Typography;
const DashBoardPrincipalArirang = () => {
  const [clicksArirang, setClicksArirang] = useState([]);

  useEffect(() => {
    Promise.all([ApiRedireccionArirang.Clicks()])
      .then((res) => {
        const clicksArirang1 = res[0];

        if (clicksArirang1.data.success) {
          setClicksArirang(clicksArirang1.data.dataList);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema " + err
        );
      });
  }, []);
  const config = {
    data: clicksArirang,
    xField: "fecha",
    yField: "clicks",
    xAxis: {
      title: {
        text: `Fecha (Dia y mes) \n Ano en curso: ${moment().format("YYYY")}`,

        style: {
          fill: "black",
          stroke: "black",
          strokeOpacity: 1,
          padding: 10,
        },
      },
      label: {
        formatter: (v) => moment(v, dateFormat).format("DD/MM"),
      },
    },
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: true,
      title: (v) => moment(v, dateFormat).format("ll"),
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return (
    <div>
      <Title level={4} style={{ textAlign: "center" }}>
        De un total de{" "}
        {clicksArirang.reduce((sum, value) => sum + value.clicks, 0)}{" "}
        interacciones.
      </Title>
      <Line {...config} />
    </div>
  );
};

export default DashBoardPrincipalArirang;
