import React from "react";
import helperSession from "../../../js/api/helpers/helpersSession";
import DashBoardPrincipalArirang from './Dashboard/DashboardPrincipalArirang';
import DashBoardPrincipalJoyeria from './Dashboard/DashboardPrincipalJoyeria';
import "./home.css";
import {Typography } from "antd";

const { Title } = Typography;

const Home = () => {
  return (
    <div>
      <div className="title">
        <Title>
          <span>
            Hola{" "}
            {helperSession.GetAccount("nombre") +
              " " +
              helperSession.GetAccount("apellido")}
            , espero que tengas un buen día.
          </span>
        </Title>
      </div>
      <div className="title">
        <Title level={3}>
          <span>
            Interacciones Totales Diarias en {helperSession.GetAccount("nombreEmpresa")} (Ultimos 30 Dias)
          </span>
        </Title>       
      </div>
      <div>
        {(() => {
          switch (helperSession.GetAccount("nombreEmpresa")) {
            case 'Arirang':
              return <DashBoardPrincipalArirang/>
            case 'Joyeria Fernandez':
              return <DashBoardPrincipalJoyeria/>
            default:
              return 
          }
        })()}
      
      </div>

    </div>
  );
};

export default Home;
