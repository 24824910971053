import React, { useEffect, useState } from "react";
import ApiVentas from '../../../../js/api/env/global/VentasMyHome';
import { openNotification } from "../../../controls/Notifications";


const Ventas = () => {
    const [loading, setLoading] = useState(false);
    const [dataVentas, setDataVentas] = useState()
    useEffect(() => {
        setLoading(true);
        Promise.all([ApiVentas.Ventas()])
          .then((res) => {
            const resDataRegistro = res[0];
            console.log(resDataRegistro)
            // if (resDataRegistro.data.success) {
            //     setDataVentas(resDataRegistro.data.dataList);
            // } else {
            //     openNotification(
            //     "e",
            //     "Carga de Datos",
            //     "Ocurrio un error en la carga de datos"
            //   );
            // }
          })
          .catch((err) => {
            openNotification(
              "e",
              "Error de sistema",
              "Ocurrio un error en el sistema: " + err
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, []);
    return (
        <div>
            
        </div>
    )
}

export default Ventas;
