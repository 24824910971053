import React, { useEffect, useState } from "react";
import ApiDashboardArirang from "../../../../../js/api/env/global/DashboardArirang";
import { openNotification } from "../../../../controls/Notifications";
import { Pie } from "@ant-design/charts";
import { Typography } from "antd";

const { Title } = Typography;

const DashboardHistorico = () => {
  const [dataHistorica, setDataHistorica] = useState([]);
  useEffect(() => {
    Promise.all([ApiDashboardArirang.DatoHistoricoIG()])
      .then((res) => {
        const dashboardHistorico = res[0];

        if (dashboardHistorico.data.success) {
          setDataHistorica(dashboardHistorico.data.dataList);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema: " + err
        );
      });
  }, []);

  const config = {
    appendPadding: 10,
    data: dataHistorica,
    angleField: "clicks",
    colorField: "nombreRedireccion",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage} ({value})",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div>
      <div>
        <Title level={4}>
          De un total de{" "}
          {dataHistorica.reduce((sum, value) => sum + value.clicks, 0)}{" "}
          interacciones.
        </Title>
      </div>
      <div className="chartContainer">
        <Pie height={1100} {...config} />
      </div>
    </div>
  );
};

export default DashboardHistorico;
