import React, { useEffect, useState } from "react";
import Datatable from "./Datatable";
import ApiRedireccionArirang from "../../../../../js/api/env/global/RedireccionArirang";
import { openNotification } from "../../../../controls/Notifications";
import { Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dateFormat from "../../../../../js/api/helpers/dateFormat";
import moment from "moment";

import "moment/locale/es";

moment.locale("es");

const Data = () => {
  const [loading, setLoading] = useState(false);
  const [dataRegistro, setDataRegistro] = useState([]);
  useEffect(() => {
    setLoading(true);
    Promise.all([ApiRedireccionArirang.DatosArirang()])
      .then((res) => {
        const resDataRegistro = res[0];
        if (resDataRegistro.data.success) {
          setDataRegistro(resDataRegistro.data.dataList);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema: " + err
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const filtrosVendedor = [
    ...new Set(dataRegistro.map((x) => x.nombreVendedor)),
  ];
  const filtrosRedireccion = [
    ...new Set(dataRegistro.map((x) => x.tipoRedireccion)),
  ];
  const filtrosPais = [
    ...new Set(dataRegistro.map((x) => x.pais)),
  ];
  const columns = [
    {
      title: "Nombre Vendedor",
      dataIndex: "nombreVendedor",
      key: "nombreVendedor",
      search: true,
      filters: filtrosVendedor.map((x) => ({ text: x, value: x })),
      onFilter: (value, record) => {
        return record.nombreVendedor === value;
      },
    },
    {
      title: "Tipo Redireccion",
      dataIndex: "tipoRedireccion",
      key: "TipoRedireccion",
      search: true,
      filters: filtrosRedireccion.map((x) => ({ text: x, value: x })),
      onFilter: (value, record) => {
          return record.tipoRedireccion === value;      
      },
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      search: true,
    },
    {
      title: "Navegador",
      dataIndex: "navegador",
      key: "navegador",
      search: true,
    },
    {
      title: "Interaccion",
      dataIndex: "fechaRedireccion",
      key: "fechaRedireccion",
      search: true,
      render:(value) => moment(value, dateFormat) > moment().subtract(1, "days") ? moment(value, dateFormat).fromNow() : moment(value, dateFormat).calendar() 
    },
    {
      title: "Fecha Evento",
      dataIndex: "fechaRedireccion",
      key: "fechaRedireccion",
      search: true,
      render:(value) => moment(value, dateFormat).format("DD/MM/YYYY HH:mm:ss")      
    },
    {
      title: "Sistema Operativo",
      dataIndex: "os",
      key: "os",
      search: true,
    },
    {
      title: "Dispositivo",
      dataIndex: "dispositivo",
      key: "dispositivo",
      search: true,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      search: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div style={{ padding: "10px 5px" }}>
              <Input
                autoFocus
                placeholder="Buscar Region"
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <div style={{ margin: "10px 5px 10px 0px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                >
                  Buscar
                </Button>
              </div>
              <div style={{ margin: "10px 5px 10px 0px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                >
                  Reiniciar
                </Button>
              </div>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.region.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      key: "ciudad",
      search: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div style={{ padding: "10px 5px" }}>
              <Input
                autoFocus
                placeholder="Buscar Ciudad"
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <div style={{ margin: "10px 5px 10px 0px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                >
                  Buscar
                </Button>
              </div>
              <div style={{ margin: "10px 5px 10px 0px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                >
                  Reiniciar
                </Button>
              </div>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ciudad.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Pais",
      dataIndex: "pais",
      key: "pais",
      filters: filtrosPais.map((x) => ({ text: x, value: x })),
      onFilter: (value, record) => {
          return record.pais === value;      
      },
    },
  ];

  return <Datatable data={dataRegistro} columns={columns} loading={loading} />;
};

export default Data;
