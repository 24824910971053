import Axios from "axios";
import ApiUrl from "../../env/enviroment";

const baseEndpoint = "/api/Empresas/";

const getEmpresas = async () => {
    return await Axios.get(ApiUrl + baseEndpoint); 
}

const getEmpresasById = async (id) => {
    return await Axios.get(ApiUrl + baseEndpoint + id); 
}

const ApiEmpresas = {
    getEmpresas,
    getEmpresasById
}
export default ApiEmpresas;