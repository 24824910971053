import React from "react";
import Data from './partial/Data';

const ReportesArirang = () => {
  return (
    <div>
      <Data/>
    </div>
  );
};

export default ReportesArirang;
