import React, { useEffect, useState } from "react";
import ApiDashboardArirang from "../../../../../js/api/env/global/DashboardArirang";
import { openNotification } from "../../../../controls/Notifications";
import { Pie } from '@ant-design/charts';
import { Typography } from "antd";


const { Title } = Typography;

const Dashboard7DiasArirang = () => {
    const [data7Dias, setData7Dias] = useState([]);
    useEffect(() => {
      Promise.all([ApiDashboardArirang.DatoHistoricoIG(7, 1)])
        .then((res) => {
          const dashboard7Dias = res[0];
  
          if (dashboard7Dias.data.success) {
            setData7Dias(dashboard7Dias.data.dataList);
            console.log(dashboard7Dias.data.dataList)
          } else {
            openNotification(
              "e",
              "Carga de Datos",
              "Ocurrio un error en la carga de datos"
            );
          }
        })
        .catch((err) => {
          openNotification(
            "e",
            "Error de sistema",
            "Ocurrio un error en el sistema: "+err
          );
        });
    }, []);

    const config = {
      appendPadding: 10,
      data: data7Dias,
      angleField: 'clicks',
      colorField: `nombreRedireccion`,
      radius: 0.8,
      label: {
        type: 'outer',
        content: '{name} {percentage} ({value})',
      },
      interactions: [
        {
          type: 'pie-legend-active',
        },
        {
          type: 'element-active',
        },
      ]
    };
    return (
      <div>
        <div>
          <Title level={4}>
          De un total de {data7Dias.reduce((sum, value) => sum + value.clicks, 0)} interacciones.
          </Title> 
        </div>
        <div className="chartContainer">
          <Pie height={1100} {...config} />
        </div>
      </div>
    );
}

export default Dashboard7DiasArirang
