import Axios from 'axios';
import ApiUrl from '../enviroment';


const DatoHistorico = async (fecha = 0, tipoFecha = 0) => {
    const baseEndpoint = "/api/DashboardJoyeria/GraficoHistorico";
    return await Axios.get(ApiUrl + baseEndpoint + '/' + fecha + '/' + tipoFecha); 
};

const ApiDashboardJoyeria = {
    DatoHistorico,
}

export default ApiDashboardJoyeria;