import React, {useEffect, useState} from "react";
import ApiLogin from "../../../js/api/env/global/auth/login";
import ApiEmpresas from "../../../js/api/env/global/Empresas";
import { openNotification } from "../../controls/Notifications";
import { Form, Input, Button, Select } from "antd";
import "./css/login.css";

const Login = () => {

  const [dataEmpresa, setDataEmpresa] = useState([]);
  useEffect(() => {
    Promise.all([ApiEmpresas.getEmpresas()])
      .then((res) => {
        
        const dataEmpresa1 = res[0];
        console.log(dataEmpresa1);
        if (dataEmpresa1.data.success) {
          setDataEmpresa(dataEmpresa1.data.dataList);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema: "+ err
        );
      });
  }, []);

  const onFinish = (values) => {
    const { username, password, empresa } = values;
    ApiLogin.login(username, password, empresa);
  };
  return (
    <div className="back-login">
      <div className="login-container">
        <div className="container">
          <h1>Login</h1>
          <Form
            // {...layout}
            name="loginForm"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Usuario"
              name="username"
              rules={[
                { required: true, message: "Por favor, ingrese su usuario" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[
                { required: true, message: "Por favor, ingrese su contraseña" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Seleccione Empresa"
              name="empresa"
              rules={[
                { required: true, message: "Por favor, ingrese la empresa" },
              ]}
            >
              <Select placeholder="Seleccione una Empresa">
                {dataEmpresa.map( (x) => (
                  <Select.Option value={x.idEmpresa}>{x.nombreEmpresa}</Select.Option>
                ))}                           
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" shape="round" htmlType="submit">
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
