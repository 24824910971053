import React from 'react'
import { Affix, Layout } from 'antd'
import helperSession from '../../../js/api/helpers/helpersSession';
import {
    UserOutlined
  } from '@ant-design/icons';

const {Header} = Layout;

export default function HeaderBar() {
    return (
        <>
            <Affix>
                <Header className="site-layout-background" style={{ padding: 0, background: '#5631DE'}}>
                    <div className="nombre-perfil">                   
                        <h5><UserOutlined style={{marginRight: 5}} /><span>{helperSession.GetAccount("nombre") + " " + helperSession.GetAccount("apellido")}</span></h5>
                    </div>
                </Header>
            </Affix>
        </>
    )
}
