import React from "react";
import { Button, Table, Typography } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import './../css/Datatable.css'
 

const {Title} = Typography;
const exportExcel = () => {
  const cloneDivNode = document.getElementById('antdTable').cloneNode(true);
  const table = document.createElement('table')
  table.appendChild(cloneDivNode);
  table.setAttribute('id', 'table-to-xls');
  document.getElementById('root').appendChild(table);
  document.getElementById('test-table-xls-button').click();
  setTimeout(() => {
    document.getElementById('root').removeChild(table);
  },500)
}
const Datatable = ({columns, data, loading}) => {

  return (
    <div>
      <Title level={3}>Detalle de las redirecciones</Title>
      <div style={{padding: "20px 0px"}}>
        <Button type="default" shape="round" icon={<DownloadOutlined />} onClick={exportExcel}>
          Exportar a Excel
        </Button>
      </div>
      <Table
        id="antdTable"
        rowKey="idRedireccion"
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000, y:500 }}
        loading={loading}
        pagination={{pageSize : "250"}}
        bordered
      />
      <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="reporte"
                sheet="hoja de ejemplo"
                buttonText="Descargar excel"/>
    </div>
  );
};

export default Datatable;
