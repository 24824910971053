import Axios from 'axios';

const endpoint = "https://zonaclientes.cl/rest_my_home/api/venta/getAllVentas";

const Ventas = async () => {
    return await Axios.get(endpoint);
};

const ApiVentas = {
    Ventas
};

export default ApiVentas;