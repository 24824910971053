import Axios from 'axios';
import ApiUrl from '../enviroment';


const DatoHistorico = async (fecha = 0, tipoFecha = 0) => {
    const baseEndpoint = "/api/DashboardArirang/GraficoHistorico";
    return await Axios.get(ApiUrl + baseEndpoint + '/' + fecha + '/' + tipoFecha); 
};

const DatoHistoricoIG = async (fecha = 0, tipoFecha = 0) => {
    const baseEndpoint = "/api/DashboardArirang/GraficoHistoricoIG";
    return await Axios.get(ApiUrl + baseEndpoint + '/' + fecha + '/' + tipoFecha); 
};

const ApiDashboardArirang = {
    DatoHistorico,
    DatoHistoricoIG
}

export default ApiDashboardArirang;
